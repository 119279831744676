@import "src/resources/styles/variables";
@import 'src/resources/styles/mixins';

.outerInputWrapper {
  display: flex;
  flex-direction: column;
  .label {
    margin-bottom: 4px;
    color: $dark-blue-30;
    font-family: $font-semi;
    font-size: 14px;
    line-height: 1.5;
  }
  .inputHint {
    position: absolute;
    left: 0;
    top: 35px;
    color: $gray-40;
    font-family: $font-reg;
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
  }
  .error-hint {
    color: $pink;
  }
}
.innerInputWrapper {
  position: relative;
  max-width: 480px;
  &:hover {
    .input {
      border: 1px solid $gray-40;
    }
  }
  .inputLinkIcon {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 16px;
    height: 16px;
    svg {
      fill: $gray-30;
    }
  }
  .inputCrossIcon {
    position: absolute;
    right: 8px;
    top: 15px;
    width: 20px;
    height: 12px;
    svg {
      fill: $gray-30;
      max-height: 100%;
      max-width: 100%;
    }

    &.invisible {
      opacity: 0;
      @include pointer-none;
    }
  }
  .focus-icon {
    svg {
      fill: $green-30;
    }
  }
  .error-icon {
    svg {
      fill: $pink;
    }
  }
  .input {
    width: 100%;
    padding: 0 35px 0 16px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid $gray-30;
    background-color: $white;
    outline: none;
    box-sizing: border-box;
    @include placeholder {
      font-family: $font-reg;
      font-size: 16px;
      color: $gray-40;
    }
  }
  .focus {
    border: 1px solid $green-30;
    &:hover {
      border: 1px solid $green-30;
    }
  }
  .error-input {
    border-color: $pink;
    &:hover {
      border-color: $pink;
    }
  }
  .placeholder {
    position: absolute;
    left: 17px;
    top: 8px;
    color: $gray-40;
    pointer-events: none;
  }
}

input {
  &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
    -webkit-text-fill-color: $black;
    -webkit-box-shadow: 0 0 0 100px $white inset;
    box-shadow: 0 0 0 100px $white inset;
    transition: background-color 5000s ease-in-out 0s;
    color: $black;
  }
}
