@import "src/resources/styles/mixins";
.portfolio-section {
  width: 100%;
  background: #ffffff;

  .section-title {
    margin-bottom: 70px;
    padding-top: 20px;
    text-align: center!important;

    h2 {
      font-size: 25px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #898989;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    .col-md-12 {
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;

      .portfolio-list {
        margin-bottom: 50px;
        text-align: center!important;

        .nav {
          -ms-flex-wrap: wrap;
          margin: 0;
          flex-wrap: wrap;
          padding-left: 0;
          list-style: none;
          display: inline-block;
          margin: 0;

          li {
            margin: 0 40px 0 0;
            color: #999999;
            float: left;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;
            font-family: 'Poppins', helvetica;
            font-weight: bold;
            letter-spacing: 0.02em;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  @include tablet {
    & {
      display: list-item;
      padding: 50px 10% 10vh 10%;

      .nav {
        display: grid!important;

        li {
          margin: 5px!important;
        }
      }
    }

  }
}


.portfolio-container {
  min-height:500px;
  text-align: center;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .portfolio-thumbnail {
    width: 300px;
    display: inline-block;
    margin: 10px;
    cursor: pointer;

    img {
      width: 300px;
    }
  }
  //position: relative;

  //display: inline-block;
  //margin-bottom: 50px;
  //width: 100%;
  //
  //.portfolio-thumbnail {
  //  margin-bottom: 30px;
  //  overflow: hidden;
  //  position: absolute;
  //  left: 0px;
  //  top: 0px;
  //  flex: 0 0 33.333333%;
  //  max-width: 33.333333%;
  //
  //  img {
  //    max-width: 100%;
  //    vertical-align: middle;
  //    border-style: none;
  //  }
  //}
}

//.webdesign {
//  position: absolute; left: 0px; top: 0px;
//}
//
//.photography {
//  position: absolute; left: 370px; top: 0px;
//}
//
//.photographyn {
//  position: absolute; left: 740px; top: 0px;
//}
//
//.photography-1 {
//  position: absolute; left: 0px; top: 285px;
//}
//
//.photography-2 {
//  position: absolute; left: 370px; top: 285px;
//}
//
//.webdesign-1 {
//  position: absolute; left: 740px; top: 285px;
//}