@import "src/resources/styles/mixins";
.header-content {
  background-image:  linear-gradient(rgba(190, 190, 190, 0.1), rgba(190, 190, 190,0.1)), url("../../../resources/img/my-gallery/home-bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-clip: border-box;
  background-size: cover;
  padding: 27vh 10% 10vh 20%;
  height: 100vh;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #fff;
  }

  p {
    font-size: 13px;
    letter-spacing: 5px;
    margin-top: 0;
    margin-bottom: 30px;
    text-transform: capitalize;
    color: #fff;
    font-weight: 500;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .header-content .list-social li {
    float: left;
    margin-right: 20px;

    i {
      color: #fff;
      font-size: 15px;
    }
  }

  @include tablet {
    & {
      background-position: 70% 50%;
      padding: 47vh 10% 5vh 10%!important;

      h1 {
        font-size: 30px;
      }
    }
  }
}