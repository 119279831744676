@import "src/resources/styles/mixins";
.about-section {
  width: 100%;
  height: auto;
  background: #f7f7f7;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  .about-container {
    max-width: 1140px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 150px 15px;
  }

  .justify-content-between {
    -webkit-box-pack: justify!important;
    justify-content: space-between!important;
    display: flex;
    flex-wrap: wrap;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;

    .div-img-bg {
      padding-bottom: 30px;
      border: 20px solid #b8a07e;

      img {
        width: 100%;
        box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14);
        margin-top: -60px;
        margin-left: 40px;
        height: 400px;
        object-fit: cover;
      }
    }
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;

    .p-heading {
      font-family: 'Playfair Display', serif;
      font-size: 28px;
      text-align: left;
      margin: 25px 0;
      padding: 0;
      color: #999999;
      line-height: 28px;
    }

    .separator {
      text-align: left;
      margin: 25px 0 25px 0;
      padding: 0;
      font-size: 15px;
      color: #999999;
      line-height: 28px;
    }
  }


  @include tablet {
    .row {
      display: block;

      .col-lg-4 {
        max-width: 100%;
        padding-right: 10px;

        img {
          margin-left: 30px;
          height: 350px;
        }
      }

      .col-lg-7 {
        max-width: 100%;
        padding: 0 10px;

        .separator {
          text-align: justify;
        }
      }
    }
  }
}