@import "src/resources/styles/mixins";

.not-found-main-section {
  min-height: calc(100vh - 100px);
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(rgba(255, 0, 0,0.7), rgba(255, 0, 0,0.7)), url("../../resources/img/404.jpeg");
  display: flex;

  .not-found-content {
    min-width: 370px;
    min-height: 510px;
    text-align: center;
    margin: 150px auto 0;

    h1 {
      font-size: 80px;
      padding-bottom: 10px;
    }

    h5 {
      font-size: 36px;
      margin-top: -30px;
    }

    .social-links {
      border-top: 2px solid red;
      padding-top: 20px;
      width: 60%;
      margin: auto;

      a {
        margin: 0 20px;
      }
    }

    .grey-btn {
      color: #393030;
      background-color: #717171;
      padding: 12px 50px;
      font-size: 16px;
      font-variant: small-caps;
      text-transform: uppercase;
      font-weight: 900;
      border-left: 20px solid black;
      border-top: none;
      border-bottom: none;
      border-right: none;
      border-radius: 4px;
      transition: all 0.1s ease-out;
      cursor: pointer;
      margin-top: 16rem;
      opacity: 0.6;
      min-width: 370px;
      min-height: 60px;
    }
  }

  //@include tablet {
  //  padding: 8vh;
  //}
  //@include mobile {
  //  background-image: url("../../../resources/img/bg_main-section.jpg");
  //  padding: 5vh 2vh;
  //  h1 {
  //    font-size: 3rem;
  //  }
  //  h3 {
  //    font-size: 2.4rem;
  //  }
  //  h4 {
  //    font-size: 2rem;
  //  }
  //}
}
