@import "src/resources/styles/mixins";
.main-section {
  min-height: calc(100vh - 100px);
  background-position: 50% 50%;
  background-size: cover;
  @include tablet {
    padding: 8vh;
  }
  @include mobile {
    background-image: url("../../../resources/img/my-gallery/home-bg-1.jpg");
    padding: 0;

    h1 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2.4rem;
    }
    h4 {
      font-size: 2rem;
    }
  }
}

//.first-section {
//  min-height: calc(100vh);
//  background-image:  linear-gradient(rgba(190, 190, 190, 0.1), rgba(190, 190, 190,0.1)), url("../../../resources/img/my-gallery/sharov.png");
//  background-repeat: no-repeat;
//  background-position: 50% 50%;
//  background-clip: border-box;
//  background-size: cover;
//  padding: 27vh 10% 10vh 45%;
//
//  .first-section-content {
//    font-size: 35px;
//
//    @include tablet {
//      margin-top: 110px;
//    }
//
//    span {
//      color: #AB0000;
//    }
//  }
//
//  .first-section-content-disc {
//    font-size: 26px;
//  }
//

//}

.second-section {
  min-height: calc(100vh - 100px);
  display: flex;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-clip: border-box;
  background-size: cover;
  padding: 0 10% 10vh 10%;
  color: #fff;
  margin-bottom: 70px;

  .brd-right {
    border-right: 1px solid grey;
  }

  .brd-top {
    border-top: 1px solid grey;
  }

  .brd-left {
    border-left: 1px solid grey;
  }

  .brd-bottom {
    border-bottom: 1px solid grey;
  }

  .ui-block {
    width: 50%;
    text-align: center;
    min-height: 300px;
    margin: auto;
    margin-top: 25px;
    font-size: large;
  }

  .ui-ways {
    justify-content: center;
    display: flex;

    div {
      width: 285px;
      height: 285px;
      justify-content: center;
      display: inline-grid;
      padding: 50px 0;
    }
  }

  @include tablet {
    & {
      display: list-item;
      padding: 50px 10% 10vh 10%;
    }

    .second-section-alpha {
      background-image: none;
      padding: 0;
      text-align: center;
      margin: auto;
      min-height: 150px;

      p {
        width: 300px;
        line-height: 1.5em;
      }
    }

    .brd-bottom {
      border-bottom: 1px solid #C4C4C4;
    }

    .brd-right {
      border-right: 1px solid #C4C4C4;
    }

    .ui-block {
      width: auto;

      .ui-ways div{
        width: 285px;
        height: 185px;
        justify-content: center;
        display: inline-grid;
        padding: 10px 0;
        background-image: none;

        img {
          width: 130px;
        }
      }
    }
  }
}

.forms-section {
  min-height: calc(100vh - 100px);

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-clip: border-box;
  background-size: cover;
  color: #fff;
  justify-content: center;

  .grey-btn {
    color: #393030;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 12px 50px;
    font-size: 16px;
    font-variant: small-caps;
    text-transform: uppercase;
    font-weight: 900;
    border: none;
    border-radius: 4px;
    transition: all 0.1s ease-out;
    cursor: pointer;
    margin-top: 6rem;
    opacity: 1;
    min-width: 270px;
    min-height: 70px;
    margin-bottom: 2rem;
  }

  .cards-section {
    display: inline-flex;
    position: relative;
    top: -100px;

    p {
      font-size: 40px;
      font-weight: bold;

      span {
        font-size: 20px;
      }
    }

    div {
      width: 370px;
      height: 230px;
      border: 1px solid white;
      margin: 0px 10px 10px;
      border-radius: 10px;
    }
  }

  .desc-section {
    justify-content: center;
    margin-top: 50px;
  }

  & {
    text-align: center;
  }

  @include tablet {
    & {
      padding: 0;
    }

    .cards-section {
      display: block;
      top: -75px;
      right: 3%;

      .forms-card-third {
        margin-top: 0;
      }

      div {
        width: 343px;
        height: 203px;
        border: 1px solid white;
        margin: 0px 10px 10px 30px;
        border-radius: 10px;
      }
    }

    .desc-section {
      margin-top: -30px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .grey-btn {
      margin-bottom: 3rem;
    }
  }
}

.gallery-section {

  color: #fff;
  justify-content: space-between;
  display: flex;

  .photo-alpha-fit {
    background-color: rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-clip: border-box;
    background-size: cover;
    min-height: 500px;

    .alpha-fit-content {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      min-height: 300px;
      text-align: left;
      padding-left: 15%;
      padding-top: 25%;
      padding-right: 25%;
      font-size: large;

      h2 {
        padding-top: 30px;
        font-size: xx-large;
      }

    }
  }

  .carousel {
    background-color: rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-clip: border-box;
    background-size: cover;

    img {
      height: 500px;
    }
  }

  .ui-block {
    width: 50%;
    text-align: center;
    //min-height: 300px;
    margin: auto;

    .ui-ways {
      justify-content: center;
      display: flex;

      div {
        width: 285px;
        height: 285px;
        justify-content: center;
        display: inline-grid;
        padding: 50px 0;
      }
    }
  }
  @include tablet {
    & {
      background-color: rgba(0, 0, 0, 0.25);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-clip: border-box;
      background-size: cover;
      display: block;
      padding-bottom: 20px;

      .photo-alpha-fit {
        background: none;
        min-height: 150px;
        padding-top: 50px;
      }
    }

    .ui-block {
      width: 100%;

      .alpha-fit-content {
        padding: 30px 0px 0px 0px;
        text-align: center;
        background: none;

        p {
          line-height: 1.5em;
          padding: 0 20px;
          font-size: 16px;
        }
      }
    }

    .gallery-section {
      .ui-block {
        width: 100%;
      }
    }

    .carousel img, .carousel button {
      height: 300px;
    }
  }
}

.contact-section {
  min-height: calc(100vh - 100px);
  background-color: rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-clip: border-box;
  background-size: cover;
  min-height: calc(70vh - 100px);
  position: relative;
  color: #fff;

  .contact-info {
    width: 450px;
    min-height: 600px;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 60px 50px;
    position: absolute;
    text-align: right;
    z-index: 1;
    right: 150px;

    h5 {
      font-weight: 400;
    }

    .social-links {
      border-top: 2px solid red;
      padding-top: 20px;
      width: 90%;
      margin-left: 43px;

      a {
        margin: 0 20px;
      }
    }
  }

  @include tablet {
    & {
      padding: 0;
      background: none;

      .contact-info {
        width: 100%;
        text-align: center;
        padding: 60px 20px;
        position: unset;

        h3 {
          font-size: 28px;
          font-weight: bold;
        }

        .social-links {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
.info-safety-section{
  min-height: 800px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../../../resources/img/combined-biceps-bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  //background-attachment: fixed;
  background-clip: border-box;
  background-size: cover;
  text-align: center;

  .head-info {

    .bottom-text {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    h2 {
      color: white;
      padding-top: 15px;
    }
    p {
      color: #B5B5B5;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @include tablet {
    background-position: 20% 50%;
  }
}

.info-section{
  min-height: 800px;
  background-image:linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url("../../../resources/img/info-background.png");
  background-repeat: no-repeat;
  background-position: 15% 50%;
  //background-attachment: fixed;
  background-clip: border-box;
  background-size: cover;
  text-align: center;


  .head-info {

    h2 {
      color: white;
      padding-top: 15px;
    }
    p {
      color: #B5B5B5;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .section-content {
    p {
      color: #B5B5B5;
    }

    .section-services {
      @include tablet {
        display: inline;
      }

      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

}

.program-section {
  min-height: calc(80vh - 100px);
  background-image: url("../../../resources/img/bg-prg.png");
  background-color: red;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  //background-attachment: fixed;
  background-clip: border-box;
  background-size: cover;
  padding: 15vh 10% 0vh 10%;
  color: #fff;

  .alpha-fit-log {
    margin-bottom: 30px;

    h3 {
      font-size: 30px;
    }
  }

  .program-section-content {
    width: 100%;
    display: flex;
  }

  .ui-block {
    width: 30%;
    text-align: left;
    min-height: 300px;
    margin: auto;

    button {
      min-width: 320px;
    }

    h5{
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .scrollUp-btn {
    position: absolute;
    left: 50%;
    width: 30px;
    height: 30px;
    background-image:  linear-gradient(rgba(#4F4A4A, 0.7), rgba(#4F4A4A, 0.7));
    color: #FFF;
    font-size: 40px;
    border: none;
    padding-top: 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  @include tablet {
    & {
      padding: 5vh 10% 10vh 10%;

      .alpha-fit-log {
        text-align: center;

        h4 {
          font-size: 24px;
          margin: 0;
        }

        img {
          display: none;
        }
      }

      .program-section-content {
        display: block;
      }

      .ui-block {
        width: 100%;

        h5 {
          font-size: 24px;
          font-weight: bold;
        }

        p {
          line-height: 1.5em;
          padding: 0 20px;
          font-size: 16px;
        }

        button {
          min-width: 100%;
        }
      }
    }
  }
}

.forms-card-text {
  position: relative;

  @include tablet{
    top: -75px;
    padding-left: 15px;
    padding-right: 15px;
  }

  top: -85px;
  font-size: large;

  h5, p, span {
    color: white;
    font-style: italic;
    font-weight: 400;
  }
}

.main-action {
  padding: 12px 50px;
  font-size: 16px;
  font-variant: small-caps;
  text-transform: uppercase;
  font-weight: 900;
  background: rgba(200, 8, 8, 0.7);
  border: none;
  border-radius: 4px;
  transition: all 0.1s ease-out;
  cursor: pointer;
  margin-top: 6rem;
  opacity: 0.6;
  min-width: 370px;
  min-height: 60px;
  border-left: 30px solid rgba(200, 8, 8, 1);

  img {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    transition: transform 0.2s ease-out;
  }

  &:hover {

    img {
      transform: translateX(10px);
    }
  }

  &:active {
    box-shadow: -3px 6px 14px 0 rgba(#717171, 0.9);

    img {
      transform: translateX(20px);
    }
  }
}
