@import "src/resources/styles/mixins";

.app-footer {
  width: auto;
  background: #f7f7f7;
  padding-top: 50px;
  padding-bottom: 50px;
}

.app-footer {
  background-size: cover;
  margin: auto;

  .app-footer-context {
    margin: 40px 200px;
    padding-top: 15px;
  }

  .footer-text {
    padding-top: 40px;

    span {
      font-weight: 300;
    }
  }

  .social-links {
    text-align: center;

    a {
      position: relative;
      text-decoration: none;
      align-self: center;
      padding: 20px;
      font-size: 1.6rem;


      svg {
        color: #999;
      }

      @include tablet {
        padding: 10px;
      }

      &.logo {
        font-size: 2.4rem;
        text-transform: uppercase;
        font-weight: 900;
      }
    }

  }

  @include tablet {
    & {
      display: block;
      background-color: #1F1F1F;
      width: auto;
      text-align: center;
      padding: 10px;

      .social-links {
        margin-top: 20px;
      }

      .app-footer-context {
        margin: 0;
      }
    }

    .logo {
      margin-right: 0;
    }
  }
}
