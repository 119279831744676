@import "src/resources/styles/mixins";

.contact-section {
  height: auto;
  width: 100%;
  padding-top: 90px;
  padding-bottom: 90px;
  background: #fff;

  .container {
    max-width: 1140px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

    .contact-contact h2 {
      margin-bottom: 30px;
      color: #292929;
      font-weight: 600;
    }

    .mb-30 {
      margin-bottom: 30px;
    }

    h2 {
      color: #292929;
      font-weight: 600;
    }

    .contact-details {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding-bottom: 10px;

        span {
          color: #898989;
        }
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    .sendmessage {
      color: #18d26e;
      border: 1px solid #18d26e;
      display: none;
      text-align: center;
      padding: 15px;
      font-weight: 600;
      margin: 15px;
    }

    .errormessage {
      color: red;
      display: none;
      border: 1px solid red;
      text-align: center;
      padding: 15px;
      font-weight: 600;
      margin: 15px;
    }

    input {
      width: 100%;
      background-color: transparent;
      border: 1px solid #999;
      border-radius: 0;
      color: #999999;
      font-size: 12px;
      font-weight: 500;
      height: 50px;
      letter-spacing: 0.1em;
      margin: 0 0 25px 0;
      line-height: 14px;
      text-transform: uppercase;
      display: block;
      padding: .375rem .75rem;
      background-clip: padding-box;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .validation {
      color: red;
      display: none;
      margin: 0 0 20px;
      font-weight: 400;
      font-size: 13px;
    }

    .btn {
      width: 100%;
      background: #333;
      border: medium none;
      border-radius: 0;
      color: #fff;
      font-size: 12px;
      height: 50px;
      line-height: 50px;
      padding: 0 30px;
      text-transform: uppercase;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

    .btn-send {
      width: 100%;
    }

    textarea {
      overflow: auto;
      resize: vertical;
      font-family: inherit;
      font-size: 12px;
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      height: 50px;
      letter-spacing: 0.1em;
      margin: 0 0 25px 0;
      line-height: 14px;
      text-transform: uppercase;
      display: block;
      vertical-align: middle;
      color: #999999;
      padding: .375rem .75rem;
    }
  }

  @include tablet {
    .row {
      display: block;

      .col-lg-6 {
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}