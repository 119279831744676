@import "src/resources/styles/mixins";
@import "src/resources/styles/variables";

.sidebar {
  width: 62px;
  transition: width 0.2s linear;
  overflow: hidden;
  background-color: $dark-blue-30;
  height: 100vh;
  top: 0;
  left: 0;
  color: $gray-40;
  font-size: 14px;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 777;

  & + .content {
    width: calc(100% - 62px);
  }
  &_header {
    background-color: $dark-blue-40;
    height: 62px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    cursor: pointer;
    @include max1100 {
      padding-right: 5px;
    }
    .header-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 124px;
      height: 32px;
      width: 100%;
      .logo-svg {
        width: 55px;
      }
      .logo-text {
        font-size: 13px;
        color: #ffffff;
        display: none;
        padding-left: 10px;
        text-align: center;
        font-family: $font-bold;
        letter-spacing: 5px;
        text-transform: uppercase;
      }
    }
    .sidebar_arrow svg {
      transform: rotate(90deg);
      display: none;
    }
  }
  .account__links-box {
    border-bottom: 1px solid rgba($dark-blue-10, .6);
    .sidebar_main-link {
      border: none;
      cursor: pointer;
    }
    .type-account {
      padding-left: 43px;
      margin: 0;
      padding-bottom: 10px;
      .nav-link {
        position: relative;
        display: block;
        line-height: 16px;
        height: 16px;
        &:not(:first-child) {
          margin-top: 15px;
        }
        &::before {
          content: '';
          display: block;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background-color: rgba($gray-40, .5);
          position: absolute;
          top: calc(50% - 2px);
          left: -15px;
        }
        span {
          display: none;
        }
        &.account_link-active {
          color: white;
          &::before {
            background-color: $green-20;
          }
        }
      }
    }
  }
  &_main-link{
    height: 50px;
    align-items: center;
    padding-left: 21px;
    border-bottom: 1px solid rgba($dark-blue-10, .6);
    display: flex;
    &_icon{
      height: 20px;
      width: 20px;
      margin-right: 15px;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    &_text {
      color: $gray-40;
      display: none;
      max-width: calc(100% - 35px);
      white-space: nowrap;
    }
  }
  .active {
    background-color: $dark-blue-10;
    .for-stroke {
      stroke: $green-20;
    }
    .for-fill, g {
      fill: $green-20;
    }
    .sidebar_main-link_text {
      color: $green-20;
    }
  }
  &_logout-box {
    margin-top: auto;
    height: 50px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    border-top: 1px solid rgba($gray-40, .1);
    .icon {
      display: block;
      margin-right: 15px;
      padding-top: 4px;
    }
    .logout {
      line-height: 20px;
      font-family: $font-bold;
      color: $gray-40;
      display: none;
      white-space: nowrap;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
}
.sidebar-open  {
  width: 200px;
  @include max1100 {
    width: 170px;
  }

  & + .content {
    width: calc(100% - 200px);
    @include max1100 {
      width: calc(100% - 170px);
    }
  }
  .sidebar_header {
    .sidebar_arrow {
      height: 16px;
      width: 16px;
      position: relative;
      svg {
        display: block;
      }
    }
    .header-logo {
      .logo-text {
        display: inline-block;
      }
    }
  }
  .account__links-box{
    .type-account_header {
      .account-text {
        display: inline-block;
      }
    }
    .type-account {
      padding-left: 70px;
      .nav-link {
        span {
          display: block;
        }
      }
    }
  }
  .sidebar_main-link {
    .sidebar_main-link_text {
      display: block;
    }
  }
  .sidebar_logout-box {
    .logout {
      display: block;
    }
  }
}
