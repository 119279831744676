@import "src/resources/styles/mixins";
@import "src/resources/styles/variables";

.dashboard {
  .top-content {
    display: flex;
    .common-data {
      flex-basis: 31%;
      margin-right: 40px;
    }
    .cities-data {
      flex-grow: 1;
      padding: 29px;
      .title {
        margin: 0;
        font-size: 24px;
        font-family: $font-bold;
        letter-spacing: 2px;
        margin-bottom: 20px;
      }
    }
  }
  .bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 40px -20px 0;
    @include desktop {
      margin: 40px -10px 0;
    }
    .content-item {
      flex: 1 1 500px;
      margin: 0 20px 20px;
      @include desktop {
        margin: 0 10px 20px;
      }
    }
  }
}
.section__right {
  display: flex;
  align-items: center;
}