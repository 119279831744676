//fonts
$font-reg: 'TitilliumWeb-Regular', sans-serif;
$font-semi: 'TitilliumWeb-SemiBold', sans-serif;
$font-bold: 'TitilliumWeb-Bold', sans-serif;

//colors
$white: #fff;
$black: #000;

$gray-10: #f3f8fb;
$gray-20: #e7e8f0;
$gray-30: #d7d3e2;
$gray-40: #9f9aad;

$dark-blue-10: #453c5c;
$dark-blue-20: #2e2643;
$dark-blue-30: #221a38;
$dark-blue-40: #140f23;

$green-10: #69e7c7;
$green-20: #67d4b8;
$green-30: #54aE97;

$pink: #f7788f;
$orange: #ffa470;
$yellow: #f2e57f;
$lime: #a4d47a;
$blue: #92c5ff;
$violet: #c787ff;
