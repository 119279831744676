@import "src/resources/styles/mixins";
@import "src/resources/styles/variables";

.select-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid $gray-30;
  background-color: $white;
  height: 40px;
}

.selected-item {
  width: 100%;
  padding: 0 35px 0 16px;
  outline: none;
  box-sizing: border-box;
  font-family: $font-semi;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;

  @include max1100 {
    font-size: 14px;
  }
}

.select-option {
  display: block;
  width: 100%;
  padding: 8px 15px;
  font-family: $font-semi;
  font-size: 16px;
  line-height: 30px;
  color: $dark-blue-30;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: $gray-20;
  }
}

.select-option, .selected-item {
  &.active, &.approved {
    color: $green-30;
  }

  &.inactive, &.pending, &.no-dapps, &.in-progress {
    color: $gray-40;
  }

  &.no-public-key, &.no-api-key, &.declined, &.denied {
    color: $pink;
  }
}

.select-active {
  box-shadow: 2px 3px 13px 0 rgba(34, 26, 56, 0.18);
}

.select-chosen .selected-item {
  border-color: $green-30;
}

.icon-down {
  display: block;
  position: absolute;
  right: 12px;
  top: calc(50% - 6px);
  transition: transform 0.2s ease-out;

  svg {
    fill: $gray-40;
  }
}

.icon-active {
  top: calc(50% - 8px);
  transform: rotate(180deg);

  svg {
    fill: $green-20;
  }
}

.select-dropdown {
  position: absolute;
  top: calc(100% + 1px);
  background-color: $white;
  width: 100%;
  z-index: 7;
  box-shadow: 2px 3px 13px 0 rgba(34, 26, 56, 0.18);
  border-radius: 3px;
  max-height: 750px;
}
