@import 'src/resources/styles/variables';

.main-content {
  background: #f7f7f7;
}

.blog-content {
  display: flex;
  background: #f7f7f7;
  justify-content: center;
  position: relative;

  .col-lg-12 {
    position: fixed;
    left: 80px;
    top: 30px;

    .btn-back {
      width: 100%;
      border: 1px solid #333;
      border-radius: 0;
      color: #333;
      font-size: 12px;
      height: 50px;
      line-height: 50px;
      padding: 0 30px;
      text-transform: uppercase;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .blog-container {
    justify-content: center;
    min-height: 300px;
    max-width: 700px;
    background: #fff none repeat scroll 0 0;
    display: inline-block;

    .row {
      img {
        width: 100%;
      }
    }

    .padDiv {
      padding: 30px 20px;
      color: #999999;

      .list-unstyled {
        padding-left: 0;
        list-style: none;

        li {
          font-size: 12px;
          font-weight: normal;
          margin-left: 8px;
          margin-right: 8px;
          text-transform: capitalize;
          display: inline-block;
          float: none;

          a {
            color: #a2a2a2;
            margin-left: 5px;
          }
        }
      }

      h4 {
        color: #292929;
        font-size: 14px;
        font-weight: 600;
      }

      h4 a {
        display: block;
        font-size: 19px;
        line-height: 24px;
        margin: 0 0 13px 0;
        font-weight: 500;
        color: #292929;
        -moz-transition: all 0.5s ease-in-out 0s;
        -ms-transition: all 0.5s ease-in-out 0s;
        -o-transition: all 0.5s ease-in-out 0s;
        -webkit-transition: all 0.5s ease-in-out 0s;
        transition: all 0.5s ease-in-out 0s;
      }

      blockquote {
        padding: 40px;
        background-color: #f2f2f2;
        margin: 30px 0;
        border: none;
        border-left: 4px solid #b8a07e;
      }

      p {
        margin: 25px 0;
        padding: 0;
        font-size: 15px;
        line-height: 28px;
      }
    }
  }
}