@import "src/resources/styles/mixins";

.modal-main-section {
  position: fixed;
  display: flex;
  z-index: 5;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: rgba(11, 25, 48, 0.9);

  .modal-content {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    width: 800px;
    margin: auto;
    text-align: center;
    color: #212529;

    @include tablet {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    .modal-section-container {
      @include tablet {
        display: block;
      }

      display: flex;
      justify-content: space-between;
    }

    h5 {
      color: rgba(255, 255, 255, 0.8);
    }

    .section-right {
      @include tablet {
        text-align: center;
      }

      width: 50%;

      h4 {
        margin-top: 0px;
      }

      p {
        text-align: left;
        color: #999999;
        font-size: 15px;
        line-height: 28px;
        padding-right: 20px;
      }
    }

    .section-left {
      @include tablet {
        text-align: center;
      }
      text-align: left;
    }

    .social-links {
      margin: auto;
      width: 200px;
      padding-top: 30px;
      margin-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #C4C4C4;

      a {
        margin: 0 10px;
      }
    }

    .modal-btn {
      //#F2F2F2
      background-image:  linear-gradient(rgba(#F2F2F2, 0.7), rgba(#F2F2F2, 0.7));
      border-left: 30px solid #000;
      color: #070707;
    }

    .modal-close {
      text-align: right;
      padding: 10px 10px 0 0;
      margin-bottom: 50px;
    }

    .close-btn {
      background-color: #ccc;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-clip: border-box;
      background-size: cover;
      border: none;
      padding: 10px 10px 7px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
