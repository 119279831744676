@import '../../resources/styles/variables';

.auth-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto 0;
  width: 100%;

  .signup-btn-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .auth-title {
    margin: 0 0 30px;
    font-family: TitilliumWeb-Bold,sans-serif;
    font-size: 30px;
    line-height: 1.5;
    color: #221a38;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  .auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 560px;
    padding: 45px 40px 60px 40px;
    border-radius: 8px;
    box-shadow: 2px 5px 10px 0 rgba(34, 26, 56, 0.15);
    background-color: white;
    img { width: 130px; };
    .logo {
      .logo-icon {
        margin-top: 16px;
      }
    }
  }
}

.login-form {
  width: 100%;
  margin-top: 35px;
  & > div {
    margin-bottom: 60px;
  }
  & > button {
    display: block;
    margin: 0 auto;
  }
  .login-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    .button {
      margin: 0 0 30px;
    }
  }
}
.login-hint {
  display: block;
  a {
    color: green;
    font-weight: bold;
    text-decoration: underline;
    padding-left: 5px;
  }
}

.login-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-desc {
    display: block;
    margin-bottom: 30px;
    text-align: center;
    width: 380px;
  }
  .step-form {
    width: 298px;

    & > div {
      margin-bottom: 0;
    }
  }
  button {
    display: block;
    margin: 60px auto 0 auto;
  }
}
