@import 'src/resources/styles/mixins';

* {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  outline: none ;
  //color: #fff;
}

html {
  font-size: 10px;
  @include tablet {
    font-size: 8px;
  }
}

.App {
  position: relative;
  //min-height: 100vh;
 // display: flex;

  p {
    line-height: 0.8em;
  }
}

h1 {
  font-size: 4rem;
  font-weight: 900;
}

h2 {
  font-size: 3.6rem;
  font-weight: 700;
}

h3 {
  font-size: 3.2rem;
  font-weight: 700;
}

h4 {
  font-size: 2.4rem;
  font-weight: 600;
}

h5 {
  font-size: 2rem;
  font-weight: 500;
}

h6 {
  font-size: 1.6rem;
  font-weight: 500;
}
