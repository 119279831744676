@import "src/resources/styles/mixins";
.journal-blog {
  height: auto;
  width: 100%;
  background: #f7f7f7;
  padding-bottom: 60px;
  position: relative;
  padding-top: 90px;

  .container {
      max-width: 1140px;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }

  .section-title {
    margin-bottom: 70px;
    text-align: center!important;

    h2 {
      font-size: 25px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #292929!important;
    }
  }

  .journal-block {
    display: inline-block;
    height: auto;
    width: 100%;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;

      .col-lg-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;

        a {
          text-decoration: none!important;
        }

        .journal-info {
          position: relative;
          margin: 5px;

          .journal-txt {
            padding: 25px 0px;
            position: relative;

            h4 {
              color: #292929;
              font-size: 14px;
              font-weight: 600;

              a {
                display: block;
                font-size: 19px;
                line-height: 24px;
                margin: 0 0 13px 0;
                font-weight: 500;
                color: #292929;
                text-decoration: none !important;
                -moz-transition: all 0.5s ease-in-out 0s;
                -ms-transition: all 0.5s ease-in-out 0s;
                -o-transition: all 0.5s ease-in-out 0s;
                -webkit-transition: all 0.5s ease-in-out 0s;
                transition: all 0.5s ease-in-out 0s;
              }
            }

            p {
              margin: 0;
              padding: 0;
              font-size: 14px;
              line-height: 24px;
              color: #999999;
            }
          }
        }

        img {
          width: 375px;
          height: 280px;
        }
      }
    }
  }

  @include tablet {
    .row {
      display: block!important;

      .col-lg-4 {
        max-width: 100%!important;
        margin: 0 10px;
      }
    }
  }
}